import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from 'seedly-component-library';
import Button from 'seedly-component-library/button-new';
import Dialog from 'seedly-component-library/dialog';
import { AlertCircle } from 'react-feather';
import { COMMUNITY_GUIDELINE_URL } from 'constants/app';
import { closeReportModal } from 'dispatcher/modalDispatcher';
import { postReport } from 'dispatcher/reportDispatcher';
import {
  COMMENTABLE_TYPES,
  CommentableTypeToRecordType,
} from 'entity/comment/commentTypes';
import commentApi from 'entity/comment/commentApi';
import { RootState } from 'reducer';

const ReportModal = () => {
  const isReportModalOpen = useSelector(
    (state: RootState) => state.modal.isReportModalOpen,
  );

  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState({ title: '' });
  const [loading, setLoading] = useState(false);

  const { data, commentableType, commentId, id } = useSelector(
    (state: RootState) => state.modal.reportModalMeta,
  );

  return (
    <Dialog
      isOpen={isReportModalOpen}
      headerTitle="Report"
      onCloseClick={() => dispatch(closeReportModal())}
    >
      <div className="flex flex-col gap-4">
        <p className="text-neutral-500">
          To help us understand the situation better, how would you describe the{' '}
          {data.name}?
        </p>
        <div className="flex flex-wrap w-[85%] lg:w-full">
          {data.options.map(option => (
            <Button
              variant={
                selectedOption.title === option.title ? 'primary' : 'outline'
              }
              onClick={() => setSelectedOption(option)}
              data-testid={`report-option-${option.title}`}
              key={option.title}
              className="mb-2 ml-2"
            >
              {option.title}
            </Button>
          ))}
        </div>
        <div className="flex items-center">
          <AlertCircle
            className="mr-2 fill-neutral-500"
            color="white"
            size={15}
          />
          <p className="text-xs text-neutral-500">
            Learn more about our community guidelines{' '}
            <a
              href={COMMUNITY_GUIDELINE_URL}
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-blue-500 text-xs text-neutral-500"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
      <div className="flex flex-row-reverse items-end">
        <Button
          disabled={!selectedOption.title}
          isLoading={loading}
          className="mt-4"
          onClick={() => {
            setLoading(true);
            if (COMMENTABLE_TYPES.includes(commentableType)) {
              commentApi
                .reportComment({
                  commentId,
                  commentableId: id,
                  recordType: CommentableTypeToRecordType[commentableType],
                  reason: selectedOption.title,
                })
                .then(response => {
                  if (response.ok) {
                    Message.success('Thank you, your report is submitted!');
                  } else {
                    Message.error('Failed to submit report');
                  }
                })
                .finally(() => {
                  setLoading(false);
                  dispatch(closeReportModal());
                });
            } else {
              dispatch(
                postReport(
                  { id, commentId, reason: selectedOption.title },
                  {
                    commentableType,
                    onComplete: () => {
                      dispatch(closeReportModal());
                      setLoading(false);
                    },
                    reportType: data.type,
                  },
                ),
              );
            }
          }}
          data-testid="button-submit-report"
        >
          Submit Report
        </Button>
      </div>
    </Dialog>
  );
};

export default ReportModal;
